import Router from 'next/router'
import Footer from './footer'

export default function Seemore () {

    const goDonate = () => {
        Router.push('/donate')
    }

    const goGoogle = () => {
        Router.push('https://play.google.com/store/apps/details?id=com.google.android.googlequicksearchbox&hl=en&gl=US')
    }

    const goApple = () => {
        Router.push('https://apps.apple.com/us/app/google/id284815942')
    }

    return (
        <>
            <div className='lbmc'>
                <div>
                    <div>
                        Donate just $10 and get a video of your actual contribution being made.
                    </div>
                </div>
                <div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <div className="donate" onClick={goDonate}>
                        Donate
                    </div>
                </div>
                <div style={{ paddingTop: 30 }}>
                    <div style={{ textAlign:'center' }}>
                        Download HelpHero app to get the most of it.
                    </div>
                    <div>
                        <div className='appstores'>
                            <div className='gplay' onClick={goGoogle}>
                                <img src="/gplay.png" alt="HelpHero" className="img_gplay"/>
                            </div>
                            <div className='aplay' onClick={goApple}>
                                <img src="/aplay.png" alt="HelpHero" className="img_aplay"/>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <style jsx>{`
                .donate {
                    background-color: #6C63FF;
                    color: white;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin-top: 10px;
                    border-radius: 4px;
                }
                .lbmc {
                    position:relative;
                    padding: 10px;
                }

                .appstores {
                    display:flex;
                    flex-direction:row;
                    justify-content:space-between;
                    padding-top:12px;
                    padding-left:4%;
                    padding-right:4%;
                }

                .gplay {

                }
                .aplay {

                }
                .img_aplay {
                    width:150px;
                }
                .img_gplay {
                    width:170px;
                }
            `}</style>
        </>
    )
}